.p-siteCheck {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    form {
        padding: rem(13) 0 rem(16);
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
