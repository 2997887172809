.o-carousel {
    $root: &;
    position: relative;

    &_arrow {
        $arrow: &;
        border-radius: 50%;
        height: rem(28);
        transition: $transition-long ease;
        width: rem(28);
        z-index: 1;

        &::before {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(28);
            content: '';
            display: block;
            height: 100%;
            opacity: 1;
            transition: $transition-long ease;
            width: 100%;
        }

        &.green {
            &:hover {
                &::before {
                    opacity: 0.5;
                }
            }
        }


        &_prev {
            left: rem(-38);

            &.white {
                left: calc(50% - 40px);
                bottom: rem(-44);
                top: unset;

                &:not(.slick-disabled) {
                    &::before {
                        background-image: url('~assets/icons/ic_arrow_circle_prev_white.svg');
                    }
                }

                &::before {
                    background-image: url('~assets/icons/ic_arrow_circle_prev_white_disabled.svg');
                }
            }
  
            &.green {
                &:not(.slick-disabled) {
                    &::before {
                        background-image: url('~assets/icons/ic_arrow_circle_prev.svg');
                    }
                }

                &::before {
                    background-image: url('~assets/icons/ic_arrow_circle_prev_disabled.svg');
                }
            }
        }

        &_next {
            right: rem(-38);

            &.white {
                right: calc(50% - 40px);
                bottom: rem(-44);
                top: unset;

                &:not(.slick-disabled) {
                    &::before {
                        background-image: url('~assets/icons/ic_arrow_circle_next_white.svg');
                    }
                }

                &::before {
                    background-image: url('~assets/icons/ic_arrow_circle_next_white_disabled.svg');
                }
            }
  
            &.green {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_circle_next_disabled.svg');
                }

                &:not(.slick-disabled) {
                    &::before {
                        background-image: url('~assets/icons/ic_arrow_circle_next.svg');
                    }
                }
            }
        }

        &.slick-disabled {
            cursor: no-drop;
        }
    }

    .slick-slide {
        outline: none;

        div {
            &:focus {
                outline: 0;
            }
        }
    }
}

