.t-modalImage {
    position: relative;

    &_slide {
        position: absolute;
        background: rgba(9, 10, 10, 0.6);
        border-radius: 200px;
        padding: rem(2) rem(16);
        bottom: rem(16);
        left: 50%;
        transform: translateX(-50%);
    }

    &_item {
        img {
            width: 100%;
            max-width: 100%;
        }
    }
}
