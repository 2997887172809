.p-outletInfo {
    .container {
        padding-top: rem(14);
        padding-bottom: rem(10);
    }

    &_distance {
        padding: 0 rem(4);
        border-radius: rem(2);

        &-redOrange {
            background-color: $red-orange;
        }

        &-laSalleGreen {
            background-color: $la-salle-green;
        }
    }

    &.laSalleGreen {
        background-color: $la-salle-green;
    }

    &_imageModal {
        overflow: hidden;
        margin: rem(-16);

        img {
            width: 100%;
            max-width: 100%;
        }
    }

    &_row {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-10);
        margin-right: rem(-10);
    }

    &_col {
        width: 100%;
        padding: 0 rem(10);

        &-end {
            display: flex;
            justify-content: flex-end;
        }

        &-4 {
            flex: 0 0 calc(4 / 12 * 100%);
            max-width: calc(4 / 12 * 100%);
        }

        &-6 {
            flex: 0 0 calc(6 / 12 * 100%);
            max-width: calc(6 / 12 * 100%);
        }

        &-8 {
            flex: 0 0 calc(8 / 12 * 100%);
            max-width: calc(8 / 12 * 100%);
        }
    }

    .dots {
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            content: ':';
            color: $black;
        }
    }
}

.skeleton {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border: 1px dashed $mountain-meadow;
    border-radius: rem(4);
    position: relative;
    cursor: pointer;

    >div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
    }
}

.images {
    &_face {
        padding: 0 5%;

        &_row {
            @include adjust-flex(space-between);
            margin-top: rem(12);
        }

        &_col {
            flex: 0 0 35%;
            max-width: 35%;
            width: 100%;
        }

        &_note {
            margin-top: rem(12);
        }

        &_wrapper {
            padding: rem(32) rem(16) rem(16);
            background-color: $platinum;

            @include mobile-down {
                padding: rem(16) rem(16);
            }
        }
    }

    &_thumbnail {
        &_row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 rem(-3);
        }

        &_col {
            margin-top: rem(10);
            padding: 0 rem(3);
            flex: 0 0 20%;
            max-width: 20%;
            width: 100%;
        }
    }
}
