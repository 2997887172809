.p-chooseOutlet {
    position: relative;
    padding-bottom: rem(18);

    &_item {
        padding: rem(10) 0;
        border-bottom: 1px solid $anti-flash-white1;
        cursor: pointer;

        &:hover {
            background-color: $anti-flash-white;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &_loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($black, 0.1);
        z-index: 10;
        @include adjust-flex();
    }
}

.o-modalLocation_groupBtn {
    display: flex;
    flex-wrap: wrap;
    margin: rem(32) rem(-8) 0;

    > div {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        padding: 0 rem(8);
    }
}
