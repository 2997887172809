.m-datePicker {
    $root: &;
    @include adjust-flex(space-between, flex-start);
    flex-wrap: wrap;
    position: relative;

    &_label {
        display: block;
        width: 40%;
        @include font-base(14, 20);
        font-weight: 500;

        &-required {
            color: $red-orange;
            margin-left: rem(4);
            @include font-base(14, 20);
        }
    }

    &_pickerWrapper {
        flex: 1;
    }

    .m-pulldown_header {
        border: 0;
    }

    &_calendar {
        background-color: $white;
        border: 0 !important;
        box-shadow: 0 rem(30) rem(84) $black0043, 0 rem(8) rem(32) $black0043, 0 rem(3) rem(14) $black0043,
            0 1px rem(13) $black0043;
        padding: rem(24) rem(16);

        @include tablet-down {
            padding: rem(16);
        }
    }

    &_header {
        @include adjust-flex;
    }

    &_wrapper {
        appearance: none;
        margin: 0;
        outline: none;
        transition: all $transition linear;
        width: 100%;
        color: $gunmetal2;
        background: $white;
        border: rem(1) solid $gainsboro;
        border-radius: rem(4);
        padding: rem(9) rem(16);
        font-weight: 500;
        @include font-base(14, 20);

        &::placeholder {
            color: $manatee;
        }

        &:disabled {
            cursor: not-allowed;
            background: $anti-flash-white1;
        }
    }

    &_header {
        @include adjust-flex;

        .m-pulldown {
            &:first-child {
                margin-right: rem(10);
            }
        }
    }

    .react-datepicker {
        width: 100%;

        &__triangle {
            display: none;
        }

        &__week,
        &__day-names {
            display: flex;
            justify-content: space-between;
        }

        &__day--selected,
        &__day--keyboard-selected {
            background-color: $la-salle-green !important;
        }

        &__header {
            background-color: $white;
        }

        &__day-name {
            @include font-base(14, 21);
            align-items: center;
            color: $black;
            display: flex;
            flex-direction: column;
            font-weight: 700;
            height: rem(40);
            justify-content: center;
            width: rem(40);

            @include tablet-down {
                height: rem(32);
                width: rem(32);
            }
            @include mobile-down {
                height: rem(28);
                width: rem(28);
            }
        }
    }

    &-error {
        #{$root}_wrapper {
            border-color: $red-orange !important;

            &::placeholder {
                color: $red-orange;
            }
        }

        #{$root}_customInput {
            border-color: $red-orange !important;
        
            &::placeholder {
                color: $red-orange;
            }
        }
    }

    &_messageError {
        margin-top: rem(4);
        margin-left: auto;
    }

    &_customInput {
        appearance: none;
        margin: 0;
        outline: none;
        transition: all $transition linear;
        width: 100%;
        color: $gunmetal2;
        background: $white;
        border: rem(1) solid $gainsboro;
        border-radius: rem(4);
        padding: rem(9) rem(16);
        font-weight: 500;
        @include font-base(14, 20);
        margin-top: rem(8);

        &::placeholder {
            color: $manatee;
        }

        &:disabled {
            cursor: not-allowed;
            background: $anti-flash-white1;
        }
    }
}
