html,
body {
    font-family: $font-inter;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-20 {
        margin-top: rem(20);
    }

    &-16 {
        margin-top: rem(16);
    }

    &-12 {
        margin-top: rem(12);  
    }

    &-10 {
        margin-top: rem(10);
    }

    &-8 {
        margin-top: rem(8);
    }
}

.app {
    max-width: rem(1366);
    margin: 0 auto;
}

.container {
    padding: 0 rem(12);
}

.divider {
    border-bottom: 1px solid $anti-flash-white1;
    margin: rem(16) 0;
    width: 300vw;
    transform: translateX(-100vw);
}

.header {
    background-color: $white-smoke2;
    padding: rem(26) 0;
    border-bottom: 1px solid $gainsboro;
}

.panel {
    background: $white-smoke1;
    border-radius: rem(4);
    padding: rem(8);
}

.loadingWrap {
    @include adjust-flex();
}

.loadingWrapFullPage {
    @include adjust-flex();
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
}
