.t-notifyModal {
    &_desc {
        margin-top: rem(16);
        @include tablet-down {
            margin-top: rem(8);
        }
    }

    &_button {
        margin-top: rem(16);
        @include adjust-flex();
        @include tablet-down {
            margin-top: rem(8);
        }
       
        &-back,
        &-submit {
            max-width: rem(136);
            width: 100%;
        }

        &-submit {
            margin-left: rem(16);
        }
    }
}
